import React, { useState } from 'react';
import { Helmet } from 'rnd-helmet';
import {injectIntl} from "react-intl";
import { useDataLayer } from "dataLayerHelper";
import SubMenu from "@Components/SubMenu";
import NoticeInPage from "@Components/NoticeInPage";
import axios from "axios";

function RegisterConfirmation({intl, staticContext}) {
  const { locale } = intl;
  const [ verificationSent, setVerificationSent ] = useState(false);
  const [ verificationSettings, setverificationSettings ] = useState(null);

  // Generate dataLayer for the page.
  useDataLayer(locale, staticContext, "registration");

  const resendVerification = (event) => {
    event.preventDefault();
    setVerificationSent(true);

    try {
      axios.post(`${process.env.REACT_APP_API_PREFIX}/resend-confirmation/`, {userId: window.location.search.replace('?userId=', '')})
        .then(response => {
          if (response.status === 200) {
            // Display the popup from above.
            setverificationSettings({
                message: response.data.message,
                type: 'informative'
              }
            );
          }

        }).catch (e => {
          if (e.response.status === 429) {
            setverificationSettings({
                text: 'RegisterConfirmation.Limit.Exceeded',
                type: 'negative'
              }
            );
          }
      });
    }
    catch (e) {
      setverificationSettings({
        message: 'Something wen\'t wrong.',
        type: 'negative'
        }
      );
    }
  }

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({id: 'Breadcrumb.RegisterConfirmation'})} | {intl.formatMessage({id: 'Breadcrumb.Dashboard'})}</title>
        <header className="header header--text bg-variant-brand-primary"/>
      </Helmet>
      <div className="header header--text bg-variant-brand-primary">
        <div className="header__wrapper wrapper">
          <SubMenu />
          <div className="header__content header__content--l content-block has-breadcrumbs">
            <h1 className="content-block__title">
              <span className="content-block__title-top">{intl.formatMessage({id: 'RegisterConfirmation.Header.Verification.Title'})}</span>
            </h1>
          </div>
          <div className="header__media media-block">
            <img src="/myrandstad-app/assets/image/icons/corona-illustration.svg" alt="" />
          </div>
        </div>
      </div>
      <div className="block block--s">
        <div className="block__wrapper wrapper">
          <div className="block__header">
            { verificationSent && verificationSettings ? (
              <div className="hidden--from-l">
                <NoticeInPage settings={verificationSettings} />
                <br/>
              </div>
            ) : null}
            <h2 className="block__title">{intl.formatMessage({id: 'RegisterConfirmation.Body.Left.Title'})}</h2></div>
          <div className="block__content">
            { verificationSent && verificationSettings ? (
              <div className="hidden--until-l">
                <NoticeInPage settings={verificationSettings} />
                <br/>
              </div>
            ) : null}
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'RegisterConfirmation.Body.Right.Text'})}}/>
            { !verificationSent && <a href="#" className="button--text" onClick={resendVerification}>{intl.formatMessage({id: 'RegisterConfirmation.Body.Right.Link'})}</a> }
          </div>
        </div>
      </div>
    </>
  )
}

export default injectIntl(RegisterConfirmation);
