import React, {useContext, useEffect, useState} from 'react';
import {Helmet} from 'rnd-helmet';
import {injectIntl} from "react-intl";
import Form from 'form';
import dispatchRerender from 'dispatchRerender';
import { pushCandidateEvent, useDataLayer } from "dataLayerHelper";
import axios from 'axios';
import FormContext from "formContext";
import GetRouteByName from 'getRouteByName';
import SubMenu from "@Components/SubMenu";

function Login({intl, staticContext, location}) {
  const urlMessageParam = new URLSearchParams(location.search).get('message');
  const { locale } = intl;
  const {formData, setFormData, handleErrors, currentStep, showToast, setToastMessage, handleChange, setLoading} = useContext(FormContext);
  const [showConfirmation, setConfirmation] = useState(urlMessageParam);
  const dashboardUrl = GetRouteByName('dashboard').url;

  const verificationSettings = {
    text: showConfirmation === "confirmed" ? 'Login.Message.AccountWasVerified' : showConfirmation === "reset" ? 'Login.Message.ResetPasswordSuccessful' : showConfirmation === "already_verified" ? 'Login.Message.AccountAlreadyVerified' : null,
    type: showConfirmation === "confirmed" || showConfirmation === "reset" ? 'positive' : showConfirmation === "already_verified" ? 'informative' : null
  }

  const headerTitleLeft = intl.formatMessage({id: "Login.Header.Title.Left"});
  const headerTitleRight = intl.formatMessage({id: "Login.Header.Title.Right"});

  // Generate dataLayer for the page.
  useDataLayer(locale, staticContext);

  useEffect(() => {
    if (window.location.hash === "#toast=logged-out") {
      setToastMessage(intl.formatMessage({id: 'Login.LoggedOut.Text'}));
      showToast();
    }
    dispatchRerender();
    setFormData([]);
  }, []);

  const handleSubmit = event => {
    event.preventDefault();
    setLoading(true);

    // Fetch saved jobs.
    const favoriteJobs = JSON.parse(localStorage.getItem("favJobs"));
    if (favoriteJobs && formData[currentStep]) {
      formData[currentStep].savedJobs = favoriteJobs;
    }

    if (captcha?.getToken('mrnd_captcha')) {
      if (!formData[currentStep]) {
        formData[currentStep] = {};
      }
      formData[currentStep].captchaToken = captcha?.getToken('mrnd_captcha');
    }

    axios.post(`${process.env.REACT_APP_API_PREFIX}/login`, formData[currentStep])
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          pushCandidateEvent("login");

          window.location = dashboardUrl;
          setLoading(false);
        }
      })
      .catch(error => {
        handleErrors(error.response.data);
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({id: 'Menu.Login'})} | {intl.formatMessage({id: 'Breadcrumb.Dashboard'})}</title>
        <header className="header header--text bg-variant-brand-primary"/>
      </Helmet>
      <div className="header header--text bg-variant-brand-primary">
        <div className="header__wrapper wrapper">
          <SubMenu />
          {showConfirmation ? (
              <>
                <div className="header__content header__content--l content-block has-breadcrumbs">
                  <h1 className="content-block__title">
                    <span className="content-block__title-top">{intl.formatMessage({id: 'Login.Header.Verification.Title'})}</span>
                  </h1>
                </div>
                <div className="header__media media-block">
                  <img src="/myrandstad-app/assets/image/icons/corona-illustration.svg" alt="" />
                </div>
              </>
            ) :
            <div className="header__content header__content--l content-block header__content--full-width has-breadcrumbs">
              <h1 className="content-block__title">
                <span className="content-block__title-top">{headerTitleLeft}</span>
                <span className="content-block__title-bottom text--emphasis">{headerTitleRight}</span>
              </h1>
            </div>
          }
        </div>
      </div>
      <Form name='login' handleChange={handleChange} state={formData[currentStep]} handleSubmit={handleSubmit} noticeSettings={verificationSettings} divClasses='block bg-variant-white block--s' />
    </>
  )
}

export default injectIntl(Login);
